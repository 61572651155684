import React from 'react'
import Layout from "../components/layout"
import AboutImage from "../images/painting.svg"

const about = () => {

    return (
        <Layout>
            <div className="relative w-screen max-w-6xl ">
                <div className="flex flex-col mx-4 md:mx-8 md:w-1/2 lg:w-1/2">
                    <h1 className="font-customHeader">About</h1>
                    <div  className="font-customBody">
                    <p>Khardware is a family owned hardware business operating since 2001. It was previously known as SQ Fairfield Heights Hardware. </p>
                    <p>
                    We strive to provide quality service and competitive prices for our community. 
                    </p>
                    </div>
                </div>
               
                <svg className="absolute right-0 z-0 h-50vh top-0 hidden sm:hidden md:block md:h-70vh lg:block xl:max-w-6xl  xl:h-screen md:-mt-10rem"  viewBox="0 0 439 870" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.82238 358.406C5.29368 247.996 31.482 117.204 124.738 0H439V869.685C376.264 872.234 193.552 860.829 117.024 778.254C21.3652 675.036 -5.26547 508.732 0.82238 358.406Z" fill="#0061F3"/>
                </svg>

                <div className="md:absolute md:top-20 w-full z-10 flex justify-center  md:right-0 md:w-1/3 lg:top-30 lg:w-40  xl:w-1/3 xl:-mt-3rem xl:top-20" >
                    <img src={AboutImage} className="w-3/4 md:w-full md:-mt-3rem lg:-mt-32  lg:w-3/4 xl:-mt-3rem xl:w-full self-center"/>
                </div>

            </div>


            {/* <svg className="absolute right-0 z-0 h-50vh top-0 hidden sm:hidden md:block lg:hidden xl:hidden"  viewBox="0 0 439 870" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.82238 358.406C5.29368 247.996 31.482 117.204 124.738 0H439V869.685C376.264 872.234 193.552 860.829 117.024 778.254C21.3652 675.036 -5.26547 508.732 0.82238 358.406Z" fill="#0061F3"/>
            </svg> */}

           

            
        </Layout>
    )
}

export default about
